<template>
  <div class="bg" v-if="show" @click="close">
    <div class="login-wrapper animation-big" @click.stop.prevent="() => {}">
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <div class="logo"></div>
      <div class="login-way-module module-wechat">
        <div class="mobile-input">
          <input v-model="password" placeholder="请输入密码" />
        </div>
        <div class="back-action cursor-pointer" @click.stop.prevent="submit">
          <span>确认</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: "",
    };
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          //
        } else {
          //
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    async submit() {
      if (!this.password) {
        this.$toast("请输入活动密码");
        return;
      }
      const result = await this.$http.post("/activity/check-password", {
        id: sessionStorage.getItem("activity_id") || 0,
        password: this.password,
      });
      if (result.code == 200) {
        this.$toast.success("密码正确");
        this.$emit("success");
      } else {
        this.$toast.fail("密码错误");
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46, 46, 46, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .login-wrapper {
    width: 1470px;
    height: 964px;
    box-sizing: border-box;
    padding: 210px 246px 108px 246px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    // background: url(#{$PublicEnv}/pop/login/bg.png);
    // background-size: 100% 100%;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 26px;
      background: linear-gradient(92deg, #9865ff 0%, #3b70c9 56%, #1fe9ff 99%);
    }
    .close {
      width: 60px;
      height: 60px;
      background: url(#{$PublicEnv}/index/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 50px;
      top: 66px;
    }
    .title {
      letter-spacing: 4px;
      font-size: 52px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      flex-shrink: 0;
    }
    .logo {
      width: 204.12px;
      height: 98px;
      background: url(#{$PublicEnv}/activity/top_logo_3_black.png);
      background-size: 100% 100%;
    }
    .login-way-module {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .icon {
      margin-top: 0px;
      width: 240px;
      height: 240px;
      background: url(#{$PublicEnv}/pop/login/icon.png);
      background-size: 100% 100%;
      flex-shrink: 0;
    }
    .tab {
      margin-top: -30px;
      font-size: 32px;
      font-family: DIN-Regular-, DIN-Regular;
      font-weight: normal;
      color: #ffffff;
      flex-shrink: 0;
      opacity: 0.1;
      letter-spacing: 8px;
    }
    .mobile-input {
      flex-shrink: 0;
      margin-top: 50px;
      width: 946px;
      height: 112px;
      // border: 2px solid rgba(40, 200, 188, 1);
      background: linear-gradient(
        90deg,
        rgba(138, 138, 138, 0.5),
        rgba(255, 255, 255, 0)
      );
      border-radius: 30px;
      box-sizing: border-box;
      padding: 0 42px;
      display: flex;
      align-items: center;
      margin-top: 128px;
      input {
        background: none;
        outline: none;
        border: 0;
        margin: 0;
        width: 100%;
        font-size: 48px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: rgba(100, 100, 100, 1);
        &::placeholder {
          color: rgba(100, 100, 100, 1);
        }
      }
    }
    .code-input-wrapper {
      flex-shrink: 0;
      margin-top: 50px;
      width: 946px;
      height: 112px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code-input {
        flex: 1;
        height: 100%;
        margin-right: 14px;
        background: linear-gradient(
          90deg,
          rgba(138, 138, 138, 0.5),
          rgba(255, 255, 255, 0)
        );
        box-sizing: border-box;
        padding: 0 42px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        input {
          background: none;
          outline: none;
          border: 0;
          margin: 0;
          width: 100%;
          font-size: 48px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: rgba(100, 100, 100, 1);
          &::placeholder {
            color: rgba(100, 100, 100, 1);
          }
        }
      }
      .code-action {
        flex-shrink: 0;
        width: 338px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(60, 113, 255, 0.11);
        box-sizing: border-box;
        border: 2px solid rgba(60, 113, 255, 1);
        border-radius: 16px;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #3c71ff;
        }
      }
    }
    .login-action {
      flex-shrink: 0;
      margin-top: 64px;
      width: 978px;
      height: 120px;
      border-radius: 50px;
      background: linear-gradient(
        90deg,
        rgba(83, 0, 198, 1),
        rgba(68, 44, 246, 1),
        rgba(33, 175, 253, 1)
      );
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 2px;
      }
    }
    .line-wrapper {
      flex-shrink: 0;
      margin-top: 130px;
      display: flex;
      width: 100%;
      height: 36px;
      justify-content: space-between;
      align-content: center;
      position: relative;
      .line {
        flex: 1;
        height: 0;
        margin-top: 25px;
        border-top: 1px dashed rgba(186, 186, 186, 1);
      }
      span {
        flex-shrink: 0;
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #bababa;
        z-index: 10;
        margin: 0 20px;
      }
    }
    .other-way {
      flex-shrink: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      div {
        width: 122px;
        height: 122px;
        &.wechat {
          background: url(#{$PublicEnv}/pop/login/wechat.png);
          background-size: 100% 100%;
        }
      }
    }
    .tips {
      font-size: 48px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #bbbbbb;
      margin-top: 80px;
    }
    .qrcode-wrapper {
      width: 480px;
      height: 480px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid rgba(255, 255, 255, 1);
      padding: 20px;
      margin-top: 58px;
      overflow: hidden;
      .qrcode {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url(#{$PublicEnv}/loading.gif) no-repeat center;
        overflow: hidden;
      }
    }
    .back-action {
      margin-top: 100px;
      width: 100%;
      height: 124px;
      border-radius: 60px;
      background: linear-gradient(
        90deg,
        rgba(83, 0, 198, 1),
        rgba(68, 44, 246, 1),
        rgba(33, 175, 253, 1)
      );
      line-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    .login-wrapper {
      width: 3159px;
      height: 2765px;
      box-sizing: border-box;
      padding: 563px 0 497px 0;
      .close {
        width: 154px;
        height: 154px;
        right: 87px;
        top: 123px;
      }
      .title {
        font-size: 184px;
      }
      .logo {
        width: 1720px;
        height: 312px;
      }
      .icon {
        width: 1024px;
        height: 1024px;
      }
      .tab {
        font-size: 113px;
      }
      .mobile-input {
        flex-shrink: 0;
        margin-top: 399px;
        width: 100%;
        height: 328px;
        padding: 0 76.8px;
        display: flex;
        align-items: center;
        input {
          font-size: 113px;
          height: auto;
        }
      }
      .code-input-wrapper {
        margin-top: 194.56px;
        width: 100%;
        height: 328.4px;
        .code-input {
          margin-right: 61.44px;
          padding: 0 76.8px;
          display: flex;
          align-items: center;
          input {
            font-size: 113px;
            height: auto;
          }
        }
        .code-action {
          flex-shrink: 0;
          width: 865.28px;
          height: 100%;
          border-radius: 41px;
          span {
            font-size: 113px;
          }
        }
      }
      .login-action {
        margin-top: 302.08px;
        width: 2102px;
        height: 358px;
        border-radius: 168.96px;
        span {
          font-size: 184px;
          letter-spacing: 10.24px;
        }
      }
      .line-wrapper {
        flex-shrink: 0;
        margin-top: 230px;
        height: 92.16px;
        .line {
          flex: 1;
          height: 0;
          margin-top: 60px;
          border-top: 1px dashed rgba(186, 186, 186, 1);
        }
        span {
          flex-shrink: 0;
          font-size: 113px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #bababa;
          z-index: 10;
          margin: 0 60px;
        }
      }
      .other-way {
        margin-top: 160.16px;
        div {
          width: 281.6px;
          height: 281.6px;
        }
      }
      .tips {
        font-size: 123px;
        margin-top: 328px;
      }
      .qrcode-wrapper {
        width: 1440px;
        height: 1440px;
        padding: 60px;
        margin-top: 174px;
      }
      .back-action {
        margin-top: 307px;
        width: 2102px;
        height: 358px;
        border-radius: 205px;
        span {
          font-size: 184px;
        }
      }
    }
  }
}
</style>
