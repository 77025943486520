var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_c('pop-login',{attrs:{"show":_vm.popLoginShow,"projectcode":_vm.project_code},on:{"close":_vm.popLoginClose,"loginsuccess":_vm.getInfo}}),(_vm.weappPathFinished)?_c('pop-top',{attrs:{"weappPathFinished":_vm.weappPathFinished,"weappPath":_vm.weappLoginPath,"black":true,"logo":_vm.project_detail.logo,"url":_vm.project_detail.jump_link},on:{"open":_vm.popLoginOpen,"logout":_vm.popLogout}}):_vm._e(),_c('pop-password',{attrs:{"show":_vm.popPasswordShow},on:{"close":_vm.popPasswordClose,"success":_vm.popPasswordVerify}}),_c('pop-ticket',{attrs:{"show":_vm.popTicketShow,"detail":_vm.project_detail,"verify":_vm.getWeappVerify,"weappPath":_vm.weappPath,"weappStyle":_vm.weappStyle},on:{"close":_vm.popTicketClose,"refreshDetail":_vm.refreshTicket,"goPlay":() => {
        _vm.goGame();
      }}}),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"content-bg"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"img-wrapper",style:({
            background: `url(${_vm.project_detail.cover})`,
            backgroundSize: '100% 100%',
          })},[(_vm.project_detail.cover)?_c('img',{attrs:{"src":_vm.project_detail.cover}}):_vm._e()]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"detail-title"},[_c('span',[_vm._v(_vm._s(_vm.project_detail.name))]),_c('span',[_vm._v(_vm._s(_vm.project_detail.status_remark))])]),_c('div',{staticClass:"detail-time"},[(_vm.project_detail.start_time)?_c('div',{staticClass:"time-item begin"},[_c('span',[_vm._v(_vm._s(_vm.project_detail.start_time.substring(11, 16)))]),_c('span',[_vm._v(_vm._s(_vm.project_detail.start_time .substring(0, 10) .replace("-", "年") .replace("-", "月") + "日"))])]):_vm._e(),_c('div',{staticClass:"time-line"}),(_vm.project_detail.end_time)?_c('div',{staticClass:"time-item end"},[_c('span',[_vm._v(_vm._s(_vm.project_detail.end_time.substring(11, 16)))]),_c('span',[_vm._v(_vm._s(_vm.project_detail.end_time .substring(0, 10) .replace("-", "年") .replace("-", "月") + "日"))])]):_vm._e()]),(_vm.channel.channel_id)?_c('div',{staticClass:"channel",on:{"click":_vm.jumpChannel}},[_c('img',{attrs:{"src":_vm.channel.channel_logo,"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.channel.channel_name))])]):_vm._e(),(!_vm.getWeappVerify && !_vm.getWeappNoUserVerify)?_c('div',{staticClass:"action cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
                _vm.goGame();
              }).apply(null, arguments)}}},[_c('span',[_vm._v("进入活动")])]):_vm._e(),(_vm.getWeappNoUserVerify)?_c('div',{staticClass:"action cursor-pointer"},[_c('WxOpenLaunchWeapp',{attrs:{"path":_vm.weappLoginPath}},[_c('div',{style:(_vm.weappStyle.div)},[_c('span',{style:(_vm.weappStyle.span)},[_vm._v("进入活动")])])])],1):_vm._e(),(_vm.getWeappVerify)?_c('div',{staticClass:"action cursor-pointer"},[_c('WxOpenLaunchWeapp',{attrs:{"path":_vm.weappPath}},[_c('div',{style:(_vm.weappStyle.div)},[_c('span',{style:(_vm.weappStyle.span)},[_vm._v("进入活动")])])])],1):_vm._e()])]),_c('div',{staticClass:"right"},[(_vm.project_detail.href)?_c('share-content',{attrs:{"isDetail":true,"show":_vm.shareContentShow,"detail":_vm.project_detail}}):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }