<template>
  <div class="bg" v-if="show" @click="close">
    <div
      :class="`login-wrapper animation-big ${step == 2 ? 'no-before' : ''} ${
        has_pay == 1 ? 'pay-finished' : ''
      } ${editor_html && step == 1 && has_pay == 0 ? 'has-html' : ''}`"
      @click.stop.prevent="() => {}"
    >
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <div class="buy-wrapper" v-if="has_pay == 0 && step == 1">
        <img
          class="header-bg"
          :src="$ossRes('/pop/ticket/pay_step_3_bg.png')"
        />
        <p class="header-title">请购票进入活动</p>
        <div class="ticket-info-bg" v-if="editor_html">
          <div class="ticket-info">
            <div v-html="editor_html"></div>
          </div>
        </div>
        <p>
          票价 ￥<font>{{ money }}</font>
        </p>
        <div class="back-action cursor-pointer" @click.stop.prevent="goPay">
          <span>购 票</span>
        </div>
      </div>
      <div class="pay-wrapper" v-if="has_pay == 0 && step == 2">
        <img
          class="header-bg"
          :src="$ossRes('/pop/ticket/pay_step_2_bg.png')"
        />
        <p class="header-title">微信支付</p>
        <p class="pay-money">
          ￥<font>{{ money }}</font>
        </p>
        <div class="pay-qrcode">
          <iframe :src="payIframe"></iframe>
        </div>
        <div class="pay-tips">
          <img :src="$ossRes('/pop/ticket/icon_wechat.png')" />
          <span>微信扫码支付</span>
        </div>
      </div>
      <div class="pay-detail-wrapper" v-if="has_pay == 1">
        <div class="pay-detail-title">
          <img :src="$ossRes('/pop/ticket/icon_finished.png')" />
          <span>您已购票</span>
        </div>
        <div class="pay-detail-info">
          <p>
            <span>门票ID</span>
            <span>{{ tickets_id }}</span>
          </p>
          <p class="pay-detail-info-tips">生效中</p>
        </div>
        <div
          class="back-action go-action cursor-pointer"
          v-if="!verify"
          @click.stop.prevent="goPlay"
        >
          <span>进入活动</span>
        </div>
        <div class="back-action go-action cursor-pointer" v-if="verify">
          <WxOpenLaunchWeapp :path="weappPath">
            <div :style="weappStyle.div">
              <span :style="weappStyle.span">进入活动</span>
            </div>
          </WxOpenLaunchWeapp>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
export default {
  components: {
    WxOpenLaunchWeapp,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: Object,
    verify: {
      type: Boolean,
      default: false,
    },
    weappPath: String,
    weappStyle: Object,
  },
  data() {
    return {
      step: 1,
      has_pay: 0,
      money: 0,
      tickets_id: "",
      payIframe: "",
      editor_html: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          this.init();
        } else {
          this.step = 1;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    goPlay() {
      this.$emit("goPlay");
    },
    init() {
      this.has_pay = this.detail["has_pay"];
      this.money = (this.detail["money"] / 100).toFixed(2);
      this.tickets_id = this.detail["tickets_id"];
      this.editor_html = this.detail["editor_html"];
    },
    close() {
      this.$emit("close");
    },
    async getPayCheck(order_no) {
      const { code, data } = await this.$http.get("/order/get-order", {
        order_no: order_no,
      });
      if (this.show) {
        if (code == 200 && data.status == 1) {
          this.$emit("refreshDetail");
        } else {
          setTimeout(() => {
            this.getPayCheck(order_no);
          }, 1500);
        }
      }
    },
    async goPay() {
      // 创建应用支付订单
      const { code, data, msg } = await this.$http.post("/order/tickets-order");
      if (code != 200) {
        this.$toast.fail(msg);
        return;
      }
      let url = `https://show.333f.com/payment/?desc=${encodeURIComponent(
        "川云活动门票"
      )}&money=${
        this.detail["money"]
      }&account=2&project_code=zPqDQxtCgBROSEaJ&out_trade_no=${data.order_no}`;
      if (this.userInfo && this.userInfo.openid) {
        url += `&openid=${this.userInfo.openid}`;
      }
      if (!this.$utils.isMobile().mobile) {
        this.step = 2;
        this.payIframe = `${url}&mode=native`;
      } else {
        location.href = url;
      }
      this.getPayCheck(data.order_no);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46, 46, 46, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .login-wrapper {
    width: 1134px;
    height: 1030px;
    box-sizing: border-box;
    padding: 210px 246px 108px 246px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    // background: url(#{$PublicEnv}/pop/login/bg.png);
    // background-size: 100% 100%;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 24px;
      background: linear-gradient(92deg, #9865ff 0%, #3b70c9 56%, #1fe9ff 99%);
    }
    &.no-before {
      &::before {
        display: none;
      }
    }
    .close {
      width: 60px;
      height: 60px;
      background: url(#{$PublicEnv}/pop/ticket/icon_close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 50px;
      top: 40px;
      z-index: 2;
    }
    &.pay-finished {
      height: 1030px;
      .close {
        background: url(#{$PublicEnv}/index/close.png);
        background-size: 100% 100%;
      }
    }
    &.has-html {
      height: 1638px;
    }
    .buy-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      .header-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .ticket-info-bg {
        flex-shrink: 0;
        position: relative;
        width: 1192.8px;
        height: 1029.6px;
        // background: url(#{$PublicEnv}/pop/ticket/ticket_html_bg.png);
        // background-size: 100% 100%;
        margin: -50px 0;
        box-sizing: border-box;
        padding: 90px 100px 120px 100px;
        overflow: hidden;
        .ticket-info {
          width: 100%;
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          ::v-deep img {
            width: 100%;
            height: auto;
          }
          &::-webkit-scrollbar {
            width: 0 !important;
          }
        }
      }
      p {
        flex-shrink: 0;
        position: relative;
        z-index: 1;
        font-size: 40px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
      .header-title {
        flex-shrink: 0;
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        text-align: center;
        color: #fff;
        letter-spacing: 12px;
        font-size: 64px;
      }
      font {
        flex-shrink: 0;
        font-size: 88px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .pay-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      .header-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .header-title {
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        text-align: center;
        color: #fff;
        letter-spacing: 12px;
        font-size: 64px;
      }
      p {
        position: relative;
        z-index: 1;
        font-size: 48px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      font {
        font-size: 80px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #03368c;
      }
      .pay-qrcode {
        width: 288px;
        height: 288px;
        margin-top: 64px;
        iframe {
          width: 100%;
          height: 100%;
          outline: 0;
          border: 0;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
      }
      .pay-tips {
        margin-top: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80px;
          margin-right: 16px;
        }
        span {
          font-size: 40px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: #444444;
        }
      }
    }
    .pay-detail-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      margin-top: -60px;
      .pay-detail-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 56px;
          margin-right: 20px;
        }
        span {
          font-size: 48px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .pay-detail-info {
        margin-top: 40px;
        background: url(#{$PublicEnv}/pop/ticket/pay_finished_bg.png);
        background-size: 100% 100%;
        width: 864px;
        height: 744px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        p {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 36px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 400;
            color: #333333;
            &:last-child {
              width: 260px;
              height: 62px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f8f7fe;
              font-size: 40px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: 400;
              color: #3f0896;
              margin-left: 20px;
            }
          }
        }
        .pay-detail-info-tips {
          position: absolute;
          bottom: 200px;
          left: 0;
          right: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 32px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .title {
      letter-spacing: 4px;
      font-size: 52px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      flex-shrink: 0;
    }
    .logo {
      width: 204.12px;
      height: 98px;
      background: url(#{$PublicEnv}/activity/top_logo_3_black.png);
      background-size: 100% 100%;
    }
    .login-way-module {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .icon {
      margin-top: 0px;
      width: 240px;
      height: 240px;
      background: url(#{$PublicEnv}/pop/login/icon.png);
      background-size: 100% 100%;
      flex-shrink: 0;
    }
    .tab {
      margin-top: -30px;
      font-size: 32px;
      font-family: DIN-Regular-, DIN-Regular;
      font-weight: normal;
      color: #ffffff;
      flex-shrink: 0;
      opacity: 0.1;
      letter-spacing: 8px;
    }
    .mobile-input {
      flex-shrink: 0;
      margin-top: 50px;
      width: 946px;
      height: 112px;
      // border: 2px solid rgba(40, 200, 188, 1);
      background: linear-gradient(
        90deg,
        rgba(138, 138, 138, 0.5),
        rgba(255, 255, 255, 0)
      );
      border-radius: 30px;
      box-sizing: border-box;
      padding: 0 42px;
      display: flex;
      align-items: center;
      margin-top: 128px;
      input {
        background: none;
        outline: none;
        border: 0;
        margin: 0;
        width: 100%;
        font-size: 48px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: rgba(100, 100, 100, 1);
        &::placeholder {
          color: rgba(100, 100, 100, 1);
        }
      }
    }
    .code-input-wrapper {
      flex-shrink: 0;
      margin-top: 50px;
      width: 946px;
      height: 112px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code-input {
        flex: 1;
        height: 100%;
        margin-right: 14px;
        background: linear-gradient(
          90deg,
          rgba(138, 138, 138, 0.5),
          rgba(255, 255, 255, 0)
        );
        box-sizing: border-box;
        padding: 0 42px;
        display: flex;
        align-items: center;
        border-radius: 30px;
        input {
          background: none;
          outline: none;
          border: 0;
          margin: 0;
          width: 100%;
          font-size: 48px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: rgba(100, 100, 100, 1);
          &::placeholder {
            color: rgba(100, 100, 100, 1);
          }
        }
      }
      .code-action {
        flex-shrink: 0;
        width: 338px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(60, 113, 255, 0.11);
        box-sizing: border-box;
        border: 2px solid rgba(60, 113, 255, 1);
        border-radius: 16px;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #3c71ff;
        }
      }
    }
    .login-action {
      flex-shrink: 0;
      margin-top: 64px;
      width: 978px;
      height: 120px;
      border-radius: 50px;
      background: linear-gradient(
        90deg,
        rgba(83, 0, 198, 1),
        rgba(68, 44, 246, 1),
        rgba(33, 175, 253, 1)
      );
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 2px;
      }
    }
    .line-wrapper {
      flex-shrink: 0;
      margin-top: 130px;
      display: flex;
      width: 100%;
      height: 36px;
      justify-content: space-between;
      align-content: center;
      position: relative;
      .line {
        flex: 1;
        height: 0;
        margin-top: 25px;
        border-top: 1px dashed rgba(186, 186, 186, 1);
      }
      span {
        flex-shrink: 0;
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #bababa;
        z-index: 10;
        margin: 0 20px;
      }
    }
    .other-way {
      flex-shrink: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      div {
        width: 122px;
        height: 122px;
        &.wechat {
          background: url(#{$PublicEnv}/pop/login/wechat.png);
          background-size: 100% 100%;
        }
      }
    }
    .tips {
      font-size: 48px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #bbbbbb;
      margin-top: 80px;
    }
    .qrcode-wrapper {
      width: 480px;
      height: 480px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid rgba(255, 255, 255, 1);
      padding: 20px;
      margin-top: 58px;
      overflow: hidden;
      .qrcode {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url(#{$PublicEnv}/loading.gif) no-repeat center;
        overflow: hidden;
      }
    }
    .back-action {
      flex-shrink: 0;
      margin-top: 100px;
      width: 100%;
      height: 124px;
      border-radius: 60px;
      background: linear-gradient(
        90deg,
        rgba(83, 0, 198, 1),
        rgba(68, 44, 246, 1),
        rgba(33, 175, 253, 1)
      );
      line-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &.go-action {
        position: absolute;
        left: 246px;
        right: 246px;
        bottom: 50px;
        width: auto;
      }
      span {
        font-size: 48px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    .login-wrapper {
      width: 3159px;
      height: 2765px;
      box-sizing: border-box;
      padding: 563px 0 497px 0;
      &.pay-finished {
        height: 2765px;
      }
      &.has-html {
        height: 90%;
      }
      .close {
        width: 154px;
        height: 154px;
        right: 87px;
        top: 123px;
      }
      .title {
        font-size: 184px;
      }
      .logo {
        width: 1720px;
        height: 312px;
      }
      .icon {
        width: 1024px;
        height: 1024px;
      }
      .tab {
        font-size: 113px;
      }
      .mobile-input {
        flex-shrink: 0;
        margin-top: 399px;
        width: 100%;
        height: 328px;
        padding: 0 76.8px;
        display: flex;
        align-items: center;
        input {
          font-size: 113px;
          height: auto;
        }
      }
      .code-input-wrapper {
        margin-top: 194.56px;
        width: 100%;
        height: 328.4px;
        .code-input {
          margin-right: 61.44px;
          padding: 0 76.8px;
          display: flex;
          align-items: center;
          input {
            font-size: 113px;
            height: auto;
          }
        }
        .code-action {
          flex-shrink: 0;
          width: 865.28px;
          height: 100%;
          border-radius: 41px;
          span {
            font-size: 113px;
          }
        }
      }
      .login-action {
        margin-top: 302.08px;
        width: 2102px;
        height: 358px;
        border-radius: 168.96px;
        span {
          font-size: 184px;
          letter-spacing: 10.24px;
        }
      }
      .line-wrapper {
        flex-shrink: 0;
        margin-top: 230px;
        height: 92.16px;
        .line {
          flex: 1;
          height: 0;
          margin-top: 60px;
          border-top: 1px dashed rgba(186, 186, 186, 1);
        }
        span {
          flex-shrink: 0;
          font-size: 113px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #bababa;
          z-index: 10;
          margin: 0 60px;
        }
      }
      .other-way {
        margin-top: 160.16px;
        div {
          width: 281.6px;
          height: 281.6px;
        }
      }
      .tips {
        font-size: 123px;
        margin-top: 328px;
      }
      .qrcode-wrapper {
        width: 1440px;
        height: 1440px;
        padding: 60px;
        margin-top: 174px;
      }
      .buy-wrapper {
        padding-top: 380px;
        p {
          font-size: 150px;
        }
        .ticket-info-bg {
          width: 3316px;
          height: 100%;
          margin: -200px 0;
          padding: 250px 250px 350px 250px;
        }
        .header-title {
          top: 80px;
          letter-spacing: 12px;
          font-size: 200px;
        }
        font {
          font-size: 337px;
        }
      }
      .pay-wrapper {
        padding-top: 150px;
        .header-bg {
        }
        .header-title {
          top: 100px;
          letter-spacing: 12px;
          font-size: 200px;
        }
        p {
          font-size: 183px;
        }
        font {
          font-size: 306px;
        }
        .pay-qrcode {
          width: 1094px;
          height: 1094px;
          margin-top: 50px;
          iframe {
          }
        }
        .pay-tips {
          margin-top: 80px;
          img {
            width: 304px;
            margin-right: 60.8px;
          }
          span {
            font-size: 152px;
          }
        }
      }
      .pay-detail-wrapper {
        margin-top: -30px;
        .pay-detail-title {
          img {
            width: 212.8px;
            margin-right: 76px;
          }
          span {
            font-size: 182.4px;
          }
        }
        .pay-detail-info {
          margin-top: 102px;
          width: 2525px;
          height: 2175px;
          p {
            span {
              font-size: 136.8px;
              &:last-child {
                width: 988px;
                height: 235.6px;
                font-size: 152px;
                margin-left: 76px;
              }
            }
          }
          .pay-detail-info-tips {
            bottom: 760px;
            font-size: 121.6px;
          }
        }
      }
      .back-action {
        margin-top: 307px;
        width: 2102px;
        height: 358px;
        border-radius: 205px;
        span {
          font-size: 184px;
        }
      }
    }
  }
}
</style>
